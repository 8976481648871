<template>
    <layout-heading>
        <portal to="title">
            <img src="../assets/images/icon-QR.svg" alt="">
            <span>QE Test</span>
        </portal>

        <p>
            Quick Exchange would show up as a flow whenever the user's account doesn't have the necessary assets for
            something. The button below simulates needing 0.5 ETH for something like deploying a contract.
        </p>

        <button @click="showModal = true" class="btn btn-primary">Buy ETH</button>
        <Modal :show.sync="showModal">
            <template #header>
                <h3>Quick Exchange</h3>
            </template>
            <QuickExchange
                asset="ETH"
                amount="0.008"
                network="1"
            />
        </Modal>
    </layout-heading>
</template>

<script>
import Modal from "@/components/Modal";
import QuickExchange from "@/components/QuickExchange";
import {QexWidgetApi} from "@/lib/qex";
import axios from 'axios';
import {BuySellHelperImmutable, QexClient} from 'liquid-qex';
import {TapClient} from 'liquid-tap';
import {mapState} from "vuex";

export default {
    components: {QuickExchange, Modal},
    data() {
        return {
            showModal: false,
            buy: {
                name: "Ether (ETH)",
                currency: "ETH"
            },
            buyQuantity: "1",
            sell: {
                name: "USD",
                currency: "USD"
            },
            tap: new TapClient(),
            qex: null,
            quote: null,
            stream: null,
            iframeUrl: null,
            transactionId: null
        };
    },
    computed: {
        ...mapState('user', ['account']),
        helper() {
            return new BuySellHelperImmutable({
                fixSide: "buy",
                payout_settlement: {
                    method: "BLOCKCHAIN_TRANSFER"
                },
                sellCcy: this.sell.currency,
                buyCcy: this.buy.currency,
                quantity: this.buyQuantity
            })
        },
        funds() {
            if (this.stream && this.quote) {
                return this.quote.quoted_quantity;
            }
            return null;
        },
        fundsFormatted() {
            if (this.funds) {
                return `$${this.funds} ${this.sell.name}`
            }
            return '';
        },
        payout() {
            if (this.stream && this.quote) {
                return this.quote.dealable_quantity;
            }
            return this.buyQuantity;
        },
        payoutFormatted() {
            if (this.payout) {
                return `${this.payout} ${this.buy.name}`;
            }
            return '';
        }
    },
    asyncComputed: {},
    watch: {
        qex() {
            this.updateStream();
        },
        helper() {
            this.updateStream();
        }
    },
    methods: {
        updateStream() {
            if (this.qex && this.helper) {
                let stream = this.qex.quoteStreamFromHelper(this.helper);
                stream.bind('updated', this.quoteUpdated);
                if (this.stream) {
                    this.stream.unsubscribe();
                }
                this.stream = stream;
            }
        },
        quoteUpdated(quote) {
            this.quote = JSON.parse(quote);
            console.log('received updated quote', this.quote);
        },
        message(event) {
            console.log('message', event);
            if (event.data?.event === "3ds-url") {
                this.iframeUrl = event.data.payload.href;
            }
        },
        loaded() {
            if (this.iframeUrl) {
                console.log('iframe loaded');
                /**
                 *
                 * @type {HTMLIFrameElement}
                 */
                let iframe = this.$refs.iframe;

                if (iframe.src.includes('api/v1/method/card')) {
                    iframe.contentWindow.postMessage({
                        event: 'initialize',
                        payload: {}
                    }, process.env.VUE_APP_LIQUID_URL);
                }
            }
        },
        submit() {
            (async () => {
                let res = await axios.post(`${process.env.VUE_APP_LIQUID_URL}/api/v1/session`, {
                    public_api_key: process.env.VUE_APP_LIQUID_KEY
                });

                let session = res.data.payload;

                console.log(res.data);

                res = await axios.get(`${process.env.VUE_APP_LIQUID_URL}/api/v1/kyc/liquid_oauth`, {
                    params: {
                        session_id: session.session_id,
                        return_url: `https://localhost:8080/qe`
                    }
                });

                console.log(res.data.payload.app_challenge_href);

                window.open(res.data.payload.app_challenge_href, this.uid + '-liquid-auth');

                let kyc = false;
                while (!kyc) {
                    let res = await axios.get(`${process.env.VUE_APP_LIQUID_URL}/api/v1/session/${session.session_id}`);
                    let state = res.data?.payload?.kyc_state;

                    if (state) {
                        if (state.identification?.approved
                            && state.liveness?.approved
                            && state.proof_of_address?.approved) {
                            kyc = true;
                            console.log('User authenticated and KYC approved.');
                        }
                    }
                    if (!kyc) {
                        await Promise.delay(3000);
                    }
                }

                res = await
                    axios.post(`${process.env.VUE_APP_LIQUID_URL}/api/v1/transaction/intent_to_trade`, {
                        session_id: session.session_id,
                        symbol: this.quote.symbol,
                        currency: this.quote.currency,
                        side: this.helper.side(),
                        quantity: this.quote.dealable_quantity,
                        quantity_2: this.quote.quoted_quantity,
                        quote_id: this.quote.quote_id,
                        terms_of_service: {
                            accepted: true,
                            terms_href: "https://help.liquid.com/en/articles/3969828-quick-exchange-by-liquid-end-user-terms-of-use"
                        },
                        allow_at_best_recovery: true,
                        funding_settlement: {
                            method: "CARD_PAYMENT"
                        },
                        payout_settlement: {
                            method: "BLOCKCHAIN_TRANSFER",
                            input_parameters: {
                                account_key: {
                                    type: 'WALLET_ADDRESS',
                                    value: this.account
                                }
                            }
                        }
                    });

                console.log(res.data);

                let trade = res.data.payload;

                this.transactionId = trade.transaction_id;
                this.iframeUrl = `${process.env.VUE_APP_LIQUID_URL}/api/v1/method/card/${trade.funding_settlement.settlement_instruction_id}`
            })()
                .catch(console.error);
        },
        submit2() {
            let iframe = this.$refs.iframe;
            iframe.contentWindow.postMessage({
                event: 'submit',
                payload: {
                    cardholder: {
                        name: "John Smith",
                        billingAddress: {
                            addressLine1: "623 Slade Street",
                            addressLine2: "Apartment 8",
                            zip: "31313",
                            city: "Hinesville",
                            state: "Georgia",
                            country: "US"
                        },
                        phone: "9125084652"
                    }
                }
            }, process.env.VUE_APP_LIQUID_URL)
        },
        getStatus() {
            axios.get(`${process.env.VUE_APP_LIQUID_URL}/api/v1/transaction/${this.transactionId}`)
                .then(res => {
                    console.log('transaction', res.data);
                })
                .catch(console.error);
        }
    },
    mounted() {
        return;
        window.addEventListener('message', this.message);
        this.qex = new QexClient(this.tap, {
            partnerKey: process.env.VUE_APP_LIQUID_KEY
        });

        return;

        this.qexw = new QexWidgetApi(this.$refs.qex);
        this.qexw.init({
            public_api_key: process.env.VUE_APP_LIQUID_KEY,
            theme: "light",
            payout_settlement: {
                method: 'BLOCKCHAIN_TRANSFER',
                currency: 'ETH',
                quantity: '0.1',
                input_parameters: {
                    account_key: {
                        type: "WALLET_ADDRESS",
                        value: this.account
                    }
                }
            }
        });
        this.qexw.onSuccess((transaction) => {
            console.log("Transaction Complete", transaction);
        });
        this.qexw.onError((errors) => {
            console.log("Transaction Failed", errors);
        });
        this.qexw.onStepTransition((stepTransition) => {
            console.log(
                `Step transition. Old Step: ${stepTransition.old_step}, New step: ${stepTransition.new_step}`,
                stepTransition
            );
        });
    },
    destroyed() {
        if (this.stream) {
            this.stream.unsubscribe();
            this.stream = null;
        }
        window.removeEventListener('message', this.message);
    }
}
</script>

<style scoped>

</style>
