<template>
    <layout-heading>
        <portal to="title">
            <img src="../../../assets/images/logos/uniswap.svg" alt="">
            <span>Uniswap</span>
        </portal>
        <div class="uniswap-container">
            <div class="uniswap-intro">
                <h3>Buy Tokens Instantly From Uniswap</h3>
                <h4>
                    Using Ether
                    <img src="../../../assets/images/ethereum.png" height="30" alt=""/> or
                    <img src="../../../assets/images/logos/visa.png" height="20" alt=""/>
                </h4>
                <p>
                    If you have Ether, it will be used automatically. If not, you will be prompted to purchase more,
                    powered by Quick Exchange.
                </p>
            </div>
            <div v-if="!contract" class="uniswap-selector">
                <div class="uniswap-search">
                    <h5>Search for a token</h5>
                    <ContractLoader
                        no-label
                        :load-click="tokenLoaded"
                        features="erc20"
                        network="1"
                        hide-recents
                    />
                </div>
                <div class="uniswap-pick">
                    <h5>Or pick one of these popular tokens</h5>
                    <div class="uniswap-populars">
                        <div v-for="it in highlighted" class="uniswap-popular" @click="select(it.address)">
                            <div class="popular-logo">
                                <img :src="logo(it.address)" alt=""/>
                            </div>
                            <span>{{ it.name }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="uniswap-swap">
                <h4 class="token-name"><img :src="logo(address)" alt=""/>
                    <span>
                        {{ tokenData.name }}
                        <router-link to="/uniswap" class="change-token">Change Token</router-link>
                    </span>
                </h4>
                <div class="amount formulate-input" data-classification="text">
                    <label :for="$id('amount')">Amount To Buy</label>
                    <div class="amount-input-wrapper">
                        <input :id="$id('amount')" type="number" step="any"
                               v-model="amount"
                               @input="amountInput"/>
                        <span class="amount-symbol">{{ tokenData.symbol }}</span>
                    </div>
                </div>
                <h5 class="cost-heading">Total Cost</h5>
                <div class="prices">
                    <div class="price-eth">
                        <div class="formulate-input" data-classification="text">
                            <div class="price-eth-wrapper">
                                <input :id="$id('price-eth')" type="number" step="any"
                                       v-model="priceEth"
                                       @input="ethInput"/>
                                <span class="amount-symbol">ETH</span>
                            </div>
                        </div>
                    </div>
                    <span>
                        ≈
                    </span>
                    <div class="price-usd">
                        <div class="formulate-input" data-classification="text">
                            <div class="price-usd-wrapper">
                                <span class="dollar-symbol">$</span>
                                <input :id="$id('price-eth')" type="number" step="any"
                                       v-model="priceUsd"
                                       @input="usdInput"/>
                                <span class="amount-symbol">USD</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="base-rate">
                    <span class="eth">1 ETH</span> ≈
                    <span class="token">{{ baseRate }} {{ tokenData.symbol }}</span> ≈
                    <span class="usd">${{ quote.client_ask.value }} USD</span>
                </div>

                <div class="uniswap-dumbapp">
                    <button v-if="!loggedIn" class="btn btn-primary" @click="login">Sign In</button>
                    <DumbappPopover
                        v-else
                        :instance="dumbapp"
                        :values="buyQuery"
                        large
                        label="Buy"
                    />
                </div>

                <div class="notices">
                    <div>Blockwell receives a 0.5% fee from the ETH you pay with and the tokens you purchase.</div>
                    <div>Rates shown are estimates and may change slightly.</div>
                    <div>Additional fees are assessed by
                        <a href="https://www.liquid.com/quick-exchange/" target="_blank">Liquid</a>
                        when using a credit card.
                    </div>
                </div>
            </div>
        </div>
    </layout-heading>
</template>

<script>
import DumbappPopover from "@/components/DumbappPopover";
import ProgressCircle from "@/components/ProgressCircle";
import {addressRegex, getAddress} from "@blockwell/chain-client";
import {getTokenData} from "@/lib/eth/Token";
import ContractLoader from "@/views/happs/components/ContractLoader";
import {getRate, getPrice} from "@/views/happs/uniswap/uniswaplib";
import BigNumber from "bignumber.js";
import {BuySellHelperImmutable, QexClient} from "liquid-qex";
import {TapClient} from "liquid-tap";
import {mapGetters} from "vuex";

export default {
    components: {ProgressCircle, DumbappPopover, ContractLoader},
    props: {
        token: String
    },
    data() {
        const tap = new TapClient();
        return {
            tokenAddress: null,
            highlighted: [
                {
                    name: "Tether",
                    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7"
                },
                {
                    name: "Dai Stablecoin",
                    address: "0x6B175474E89094C44Da98b954EedeAC495271d0F"
                },
                {
                    name: "ChainLink",
                    address: "0x514910771AF9Ca656af840dff83E8264EcF986CA"
                },
                {
                    name: "yearn.finance",
                    address: "0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e"
                },
                {
                    name: "Maker",
                    address: "0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2"
                },
                {
                    name: "Compound",
                    address: "0xc00e94Cb662C3520282E6f5717214004A7f26888"
                }
            ],
            tap,
            qex: new QexClient(tap, {
                partnerKey: process.env.VUE_APP_LIQUID_KEY_SANDBOX
            }),
            quote: {
                quoted_quantity: null,
                client_ask: {
                    value: null
                },
                fee: {
                    quantity: null
                }
            },
            amount: this.$route.query.value || "10",
            priceEth: '',
            priceUsd: '',
            calc: {
                source: 'amount',
                value: this.$route.query.value || "10"
            }
        };
    },
    computed: {
        ...mapGetters('user', ['api', 'loggedIn']),
        loading() {
            return this.$asyncComputed.contract.updating
                || this.$asyncComputed.dumbapp.updating;
        },
        link() {
            if (this.dumbapp) {
                return '/' + this.dumbapp.shortcode;
            }
            return null;
        },
        error() {
            if (this.$asyncComputed.contract.error) {
                return this.$asyncComputed.contract.exception;
            }
            if (this.$asyncComputed.dumbapp.error) {
                return this.$asyncComputed.dumbapp.exception;
            }
            return null;
        },
        helper() {
            return new BuySellHelperImmutable({
                fixSide: "buy",
                payout_settlement: {
                    method: "BLOCKCHAIN_TRANSFER"
                },
                sellCcy: "USD",
                buyCcy: "ETH",
                quantity: this.priceEth || "1"
            });
        },
        buyQuery() {
            return {
                amount: this.priceEth
            }
        },
        priceData() {
            return {
                source: this.calc.source,
                value: new BigNumber(this.calc.value),
                token: this.tokenData,
                ethUsd: this.quote?.client_ask?.value
            }
        }
    },
    asyncComputed: {
        contract() {
            if (this.address) {
                return this.api.loadContract(this.address)
                    .then(list => list[0]);
            }
            return null;
        },
        dumbapp() {
            if (this.contract) {
                return this.api.uniswapBuyDumbapp(this.contract.id);
            }
            return null;
        },
        async address() {
            if (this.token) {
                if (addressRegex.test(this.token)) {
                    return this.token;
                } else {
                    let search = await this.api.tokenSearch(this.token);
                    console.log('search', search);

                    if (search.length > 0) {
                        return search[0].address;
                    }
                }
            }
            return null;
        },
        async tokenData() {
            if (this.address) {
                return (await getTokenData(1, [this.address]))[0];
            }
            return {};
        },
        baseRate() {
            if (this.tokenData?.address) {
                return getRate(this.tokenData);
            }
            return null;
        }
    },
    watch: {
        helper: {
            immediate: true,
            handler() {
                this.updateStream();
            }
        },
        tokenData(val) {
            if (val && val.name) {
                document.title = `Buy ${val.name} | Blockwell Wallet`;
            } else {
                document.title = `Instant Uniswap | Blockwell Wallet`;
            }
        },
        priceData: {
            deep: true,
            /**
             *
             * @param {{ source: string, value: BigNumber, token: TokenData, ethUsd?: string }} val
             */
            handler(val) {
                if (val.token?.address) {
                    (async () => {
                        switch (val.source) {
                            case 'amount':
                                this.priceEth = await getPrice(val.token, val.value);
                                this.priceUsd = new BigNumber(this.priceEth).times(val.ethUsd).toFixed(2);
                                break;
                            case 'eth':
                                this.amount = await getRate(val.token, val.value);
                                this.priceUsd = new BigNumber(val.value).times(val.ethUsd).toFixed(2);
                                break;
                            case 'usd':
                                this.priceEth = new BigNumber(val.value).div(val.ethUsd).sd(5).toString(10);
                                this.amount = await getRate(val.token, this.priceEth);
                                break;
                        }
                    })().catch(err => {
                        console.error(err);
                    });
                }
            }
        }
    },
    methods: {
        login() {
            this.$bus.emit('login_request', {
                message: "Using Uniswap requires signing in."
            });
        },
        tokenLoaded(data) {
            this.select(data.address);
        },
        logo(address) {
            if (addressRegex.test(address)) {
                return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${getAddress(address)}/logo.png`;
            }
            return '';
        },
        select(address) {
            this.$router.push(`/uniswap/${address}`);
        },
        updateStream() {
            console.log('binding stream update');
            if (this.helper) {
                let stream = this.qex.quoteStreamFromHelper(this.helper);
                stream.bind('updated', this.quoteUpdated);
                if (this.stream) {
                    this.stream.unsubscribe();
                }
                this.stream = stream;
            }
        },
        quoteUpdated(quote) {
            this.quote = JSON.parse(quote);
        },
        amountInput() {
            this.calc.source = 'amount';
            this.calc.value = this.amount;
        },
        ethInput() {
            this.calc.source = 'eth';
            this.calc.value = this.priceEth;
        },
        usdInput() {
            this.calc.source = 'usd';
            this.calc.value = this.priceUsd;
        }
    },
    destroyed() {
        if (this.stream) {
            this.stream.unsubscribe();
            this.stream = null;
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/custom.scss";

.uniswap-container {

    ::v-deep( h3 ) {
        font-size: 40px;
    }

    ::v-deep( h4 ) {
        font-size: 32px;
        margin-top: 20px;
    }
}

.uniswap-intro {
    text-align: center;

    ::v-deep( p ) {
        margin-top: 25px;
    }
}

.uniswap-search {
    max-width: 500px;
    margin: 50px auto 0;
}

.uniswap-pick {
    ::v-deep( h5 ) {
        max-width: 500px;
        margin: 0 auto 20px;
    }
}

.uniswap-populars {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.uniswap-popular {
    cursor: pointer;
    border: 1px solid rgba($primary, .1);
    padding: 20px 0;
    width: 140px;
    text-align: center;
    margin: 5px;

    .popular-logo {
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        ::v-deep( img ) {
            max-width: 60px;
            display: block;
        }
    }

    &:hover {
        box-shadow: 0 0 2px rgba($primary, .5);
    }
}

.uniswap-swap {
    max-width: 700px;
    margin: 40px auto 0;
}

.token-name {
    display: flex;
    font-weight: normal;
    justify-content: center;
    margin: 0;

    ::v-deep( img ) {
        max-height: 40px;
        display: block;
        margin-right: 10px;
    }

    ::v-deep( span ) {
        display: block;
    }
}

.change-token {
    display: block;
    font-size: 20px;
}

.amount {
    max-width: 220px;
    margin: 30px auto 0;
}

.amount-input-wrapper, .price-eth-wrapper, .price-usd-wrapper {
    position: relative;

    ::v-deep( input ) {
        padding-right: 60px;
        padding-left: 20px;
        font-size: 26px;
    }

    .amount-symbol {
        position: absolute;
        right: 8px;
        top: 0;
        font-size: 22px;
        line-height: 57px;
        color: $code-color;
        font-weight: 600;
    }
}

.price-eth-wrapper, .price-usd-wrapper {
    ::v-deep( input ) {
        padding: 30px 50px 30px 10px;
        font-size: 20px;
    }

    .amount-symbol {
        line-height: 64px;
    }

    .dollar-symbol {
        position: absolute;
        left: 8px;
        top: 0;
        font-size: 22px;
        line-height: 64px;
        color: $code-color;
        font-weight: 600;
    }
}

.price-usd-wrapper {
    ::v-deep( input ) {
        padding-left: 25px;
    }
}

.prices {
    display: flex;
    justify-content: center;
    font-size: 20px;
    align-items: center;

    .formulate-input {
        margin: 0;
    }

    & > ::v-deep(div) {
        width: 200px;
        padding: 10px;
        margin: 0 5px;
    }

    .price {
        min-width: 50px;
        text-align: right;
    }

    .symbol {
        color: $code-color;
        font-weight: 600;
        margin-left: 20px;
    }

    .dollar {
        margin-right: 5px;
    }
}

.cost-heading {
    text-align: center;
    margin-bottom: 5px;
}

.base-rate {
    text-align: center;
    color: $secondary;
    margin-top: 5px;
}

.uniswap-dumbapp {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.notices {
    text-align: center;
    margin-top: 10px;
    font-size: 15px;
    color: $secondary;
}
</style>
