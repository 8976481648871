import routerAbi from "@/assets/data/UniswapRouter02.abi.json";
import { Chain } from "@blockwell/chain-client";
import BigNumber from 'bignumber.js';
const routerAddress = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
let wethCached = null;
async function wethAddress() {
    if (!wethCached) {
        wethCached = await Chain.readString(1, routerAddress, routerAbi, 'WETH');
    }
    return wethCached;
}
export async function getRate(token, amount = '1') {
    let weth = await wethAddress();
    let res = await Chain.readStringArray(1, routerAddress, routerAbi, 'getAmountsOut', [
        new BigNumber(amount).times('1e18').toFixed(0), [
            weth, token.address
        ]
    ], 15000);
    let rate = new BigNumber(res[res.length - 1]).div(`1e${token.decimals}`);
    return rate.sd(5, BigNumber.ROUND_FLOOR).toString(10);
}
export async function getPrice(token, amount) {
    let weth = await wethAddress();
    let res = await Chain.readStringArray(1, routerAddress, routerAbi, 'getAmountsIn', [
        new BigNumber(amount).times(`1e${token.decimals}`).toFixed(0), [
            weth, token.address
        ]
    ], 15000);
    let rate = new BigNumber(res[0]).div(`1e18`);
    return rate.sd(5, BigNumber.ROUND_FLOOR).toString(10);
}
